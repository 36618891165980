import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { RouterLink } from '@spm/core-ui';
import {
  FooterSection,
  Footer,
  Dropdown,
  DropdownOption,
  FooterLinkList,
  Link,
} from '@govhhs/govhhs-design-system-react';
import LanguageDropdown from '../../NFLanguageDropdown';
import { EnvUtils } from '@spm/core';
import translations from './NFApplicationFooterMessages';
import Paths from '../../../paths';
import { ID } from '../constants';
import logo from '../../../images/NCDHHS-SEAL.png';

/**
 * The NFApplicationFooter component handles the rendering of the Application Footer
 * feature incorporating the {@link LanguageDropdown} component to manage the local selection.
 *
 * @param intl The API to format dates, numbers and strings.
 * @param location Locations represent where the app is now, where you want it to go
 * and where it was.
 *
 * @namespace NFApplicationFooter
 * @memberof features.NFApplicationFooter
 */
const NFApplicationFooter = ({ intl, location, isAnbLinkedUser }) => {
  const isANBUserLinked = (isAnbLinkedUser && isAnbLinkedUser.isANBUserLinked);
  const lang = (<LanguageDropdown />);
  if (
    location.pathname.indexOf(Paths.APPLY) >=0
  )
  {
    return (
    <Footer type="medium">
    <FooterSection
    category="primary"
    className="custom-application-footer"
    languageDropdownWidth="1/4"
    >
    <FooterLinkList listItemsWidth="1/4" listSectionWidth="3/4">
      <RouterLink data-testid={ID.NFAPPLICATION_FOOTER_FAQ_ID} to={Paths.NFFAQ} type="footer">
        {intl.formatMessage(translations.NFApplicationFooter_FAQ_Label)}
      </RouterLink>
      {/* eslint-disable no-restricted-syntax */}
      <Link
        data-testid={ID.NFAPPLICATION_FOOTER_ENROLLMENT_BROKER_ID}
        target="_blank"
        to="https://ncmedicaidplans.gov/"
      >
        {intl.formatMessage(translations.NFApplicationFooter_enrollmentBroker_Label)}
      </Link>
      <Link
        data-testid={ID.NFAPPLICATION_FOOTER_FIND_AN_OFFICE_ID}
        target="_blank"
        to="https://www.ncdhhs.gov/divisions/social-services/local-dss-directory"
      >
        {intl.formatMessage(translations.NFApplicationFooter_findAnOffice_Label)}
      </Link>
      <Link
        data-testid={ID.NFAPPLICATION_FOOTER_DHHS_ID}
        target="_blank"
        to="https://www.ncdhhs.gov/"
      >
        {intl.formatMessage(translations.NFApplicationFooter_Dhhs_Label)}
      </Link>
      <Link
        data-testid={ID.NFAPPLICATION_FOOTER_HEALTHCARE_ID}
        target="_blank"
        to="https://www.healthcare.gov/"
      >
        {intl.formatMessage(translations.NFApplicationFooter_healthCare_Label)}
      </Link>
      <RouterLink
        data-testid={ID.NFAPPLICATION_FOOTER_DISCLAIMER_ID}
        to={Paths.NFEPASSDISCLAIMER}
        type="footer"
      >
        {intl.formatMessage(translations.NFApplicationFooter_Disclaimer_Label)}
      </RouterLink>
    </FooterLinkList>
    <img className="custom-ncdhhs-seal-logo" src={logo} alt="NCDHHS seal logo"/>
</FooterSection>
</Footer>);}

const isApplicationOrSubmssionForm =
    new RegExp(`${Paths.APPLY}/-?\\d+`).test(location.pathname) &&
    !/success/.test(location.pathname);


  // Don't display footer for these paths
  if (
    location.pathname === Paths.NFLOGIN ||
    location.pathname === Paths.NFACCOUNTOPTIONS ||
    location.pathname === Paths.NFCREATENCIDACCOUNT
  )
  {
    return null;
  }

  if (isApplicationOrSubmssionForm) {
    return (
      <Footer type="medium">
      {EnvUtils.getEnvironmentProperty('REACT_APP_NF_MULTILOCALE_ENABLED') && !isANBUserLinked ? (
        <FooterSection category="secondary" 
        languageDropdown={lang} 
        languageDropdownWidth="1/6"
        >
          <FooterLinkList listItemsWidth="1/4" listSectionWidth="5/6" />
        </FooterSection>) : (
          <FooterSection category="secondary" languageDropdownWidth="1/6">
            <FooterLinkList listItemsWidth="1/4" listSectionWidth="5/6" />
        </FooterSection>
        )}
      </Footer>
    );
  }
  return (
    <Footer type="medium">
      <FooterSection
        category="primary"
        className="custom-application-footer"
        data-testid={ID.NFAPPLICATION_FOOTER_DROPDOWN_ID}
        languageDropdown={EnvUtils.getEnvironmentProperty('REACT_APP_NF_MULTILOCALE_ENABLED') && !isANBUserLinked ? lang : null}
        languageDropdownWidth="1/4"
      >
        <FooterLinkList listItemsWidth="1/4" listSectionWidth="3/4">
          <RouterLink data-testid={ID.NFAPPLICATION_FOOTER_FAQ_ID} to={Paths.NFFAQ} type="footer">
            {intl.formatMessage(translations.NFApplicationFooter_FAQ_Label)}
          </RouterLink>
          {/* eslint-disable no-restricted-syntax */}
          <Link
            data-testid={ID.NFAPPLICATION_FOOTER_ENROLLMENT_BROKER_ID}
            target="_blank"
            to="https://ncmedicaidplans.gov/"
          >
            {intl.formatMessage(translations.NFApplicationFooter_enrollmentBroker_Label)}
          </Link>
          <Link
            data-testid={ID.NFAPPLICATION_FOOTER_FIND_AN_OFFICE_ID}
            target="_blank"
            to="https://www.ncdhhs.gov/divisions/social-services/local-dss-directory"
          >
            {intl.formatMessage(translations.NFApplicationFooter_findAnOffice_Label)}
          </Link>
          <Link
            data-testid={ID.NFAPPLICATION_FOOTER_DHHS_ID}
            target="_blank"
            to="https://www.ncdhhs.gov/"
          >
            {intl.formatMessage(translations.NFApplicationFooter_Dhhs_Label)}
          </Link>
          <Link
            data-testid={ID.NFAPPLICATION_FOOTER_HEALTHCARE_ID}
            target="_blank"
            to="https://www.healthcare.gov/"
          >
            {intl.formatMessage(translations.NFApplicationFooter_healthCare_Label)}
          </Link>
          <RouterLink
            data-testid={ID.NFAPPLICATION_FOOTER_DISCLAIMER_ID}
            to={Paths.NFEPASSDISCLAIMER}
            type="footer"
          >
            {intl.formatMessage(translations.NFApplicationFooter_Disclaimer_Label)}
          </RouterLink>
        </FooterLinkList>
        <img className="custom-ncdhhs-seal-logo" src={logo} alt="NCDHHS seal logo"/>
    </FooterSection>

 
      {/* Don't modify Revision: beeb12645. This will be replaced appropriately at runtime. */}
      <p className="footer-text-custom">
        {intl.formatMessage(translations.NFApplicationFooter_BuildVersion_Label)}{' '}
        Revision: beeb12645
      </p>
    </Footer>
  );
};

NFApplicationFooter.propTypes = {
  intl: intlShape.isRequired,
  location: PropTypes.object.isRequired,
};

/**
 * Controls the rendering of the Application Footer component incorporating the
 * {@link LanguageDropdown} component to manage the locale selection.
 * @namespace
 */
export default withRouter(injectIntl(NFApplicationFooter));
